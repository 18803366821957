import { ActivityFilterType } from "../generated/graphql";

export const ActivityFilterMap = new Map([
  ["Deposit", [ActivityFilterType.Deposit]],
  ["Loan", [ActivityFilterType.Loan]],
  ["Loan payment", [ActivityFilterType.LoanPayment]],
  ["Dividends", [ActivityFilterType.Dividends]],
  ["Interest charge", [ActivityFilterType.InterestCharge]],
  ["Interest payment", [ActivityFilterType.InterestPayment]],
  ["Buy", [ActivityFilterType.Buy]],
  ["Sell", [ActivityFilterType.Sell]],
  ["Stock transfer", [ActivityFilterType.StockTransfer]],
  ["Withdrawal", [ActivityFilterType.Withdrawal]],
  ["Cash settlement", [ActivityFilterType.CashSettlement]],
  ["Internal transfer", [ActivityFilterType.IntraAccountCashTransfer]],
  ["Credit", [ActivityFilterType.Credit]],
  ["Other", [ActivityFilterType.CreditCardPayment]], // For obsolete features we don't want to call out any more
]);

export const DI_ACTIVITY_FILTER = [
  ActivityFilterType.Withdrawal,
  ActivityFilterType.Deposit,
  ActivityFilterType.IntraAccountCashTransfer,
  ActivityFilterType.DirectIndexLiquidationRequest,
  ActivityFilterType.DirectIndexTradeExecutions,
  ActivityFilterType.DirectIndexFee,
  ActivityFilterType.IntraAccountStockTransfer,
  ActivityFilterType.Dividends,
  ActivityFilterType.Credit,
];

export const DirectIndexActivityFilterMap = new Map([
  ["Withdrawal", [ActivityFilterType.Withdrawal]],
  ["Deposit", [ActivityFilterType.Deposit]],
  ["Internal transfer", [ActivityFilterType.IntraAccountCashTransfer]],
  ["Liquidation", [ActivityFilterType.DirectIndexLiquidationRequest]],
  ["Trade", [ActivityFilterType.DirectIndexTradeExecutions]],
  ["Fee", [ActivityFilterType.DirectIndexFee]],
  ["Internal stock transfer", [ActivityFilterType.IntraAccountStockTransfer]],
  ["Dividends", [ActivityFilterType.Dividends]],
  ["Credit", [ActivityFilterType.Credit]],
]);

export const TreasuryActivityFilterMap = new Map([
  ["Deposit", [ActivityFilterType.Deposit]],
  ["Withdrawal", [ActivityFilterType.Withdrawal]],
  ["Payments", [ActivityFilterType.CreditCardPayment]],
  ["Transfer", [ActivityFilterType.IntraAccountCashTransfer]],
  ["Trade", [ActivityFilterType.Buy, ActivityFilterType.Sell]],
  ["Yield", [ActivityFilterType.Dividends]],
]);

export const TradeActivityFilterMap = new Map([
  ["Buy", [ActivityFilterType.Buy]],
  ["Sell", [ActivityFilterType.Sell]],
]);

export const PLOC_ACTIVITY_FILTER = [
  ActivityFilterType.Loan,
  ActivityFilterType.LoanPayment,
  ActivityFilterType.InterestCharge,
  ActivityFilterType.InterestPayment,
];

export const PLOCActivityFilterMap = new Map([
  ["Loan", [ActivityFilterType.Loan]],
  ["Loan payment", [ActivityFilterType.LoanPayment]],
  ["Interest charge", [ActivityFilterType.InterestCharge]],
  ["Interest payment", [ActivityFilterType.InterestPayment]],
]);
